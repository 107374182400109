<template>
  <div>
    Näytämme maaperään: {{ maapera }} soveltuvia kasveja, joisen valontarve on
    {{ valoisuus }}.
    {{
      (maapera === "hapan") & (valoisuus === "aurinkoinen")
        ? "Älä istuta!!!"
        : "Loistavaa"
    }}
  </div>
  <div>Valitse haluttu kukan väri:</div>
  <div>
    <select v-model="vari" name="" id="">
      <option value="valkoinen">valkoinen</option>
      <option value="punainen">punainen</option>
      <option value="sininen">sininen</option>
      <option value="violetti">violetti</option>
      <option value="iv">ikivihreät</option>
      <option value="">tyhjennä</option>
    </select>
  </div>
  <div>
    <ul>
      <li v-for="a in filtteriLista" :key="a" @click="valitseKasvi(a)">
        {{ a.lajike }}, vaatii istutusalueeksi ,{{ a.koko }}, senttiä
      </li>
    </ul>
  </div>
  <div>
    <button @click="filtteroiLista()">filtteröi kasvit</button>
  </div>
  <div>käytettävä väri: {{ vari }} ja valoisuus: {{ valoisuus }}</div>
</template>

<script>
//import BaseButton from './UI/BaseButton.vue';
export default {
  //components: { BaseButton },
  props: ["maapera", "valoisuus", "lajikeLista"],
  emits: ["kasvinKlikkaus"],
  data() {
    return {
      id: null,
      lajike: null,
      kukintaAlkaa: null,
      kukintaPaattyy: null,
      // valonTarve: null,
      kukinnanVari: null,
      koko: null,
      korkeus: null,
      filtteriLista: [],
      vari: null,
    };
  },

  /*
methods: {
    lisaaKasvi() {
        const lisattavaKasvi={
            id: "id"+max[this.lajikelista]+1,
            lajike: this.lajike,
            kukintaAlkaa: this.kukintaAlkaa,
            kukintaPaattyy: this.kukintaPaattyy,
            valonTarve: this.valonTarve,
            kukinnanVari: this.kukinnanVari,
            koko: this.koko,
            korkeus: this.korkeus
        }
        this.lajikeLista.unshift(lisattavaKasvi)
        console.log(this.lajikeLista)
    }
  },
  */
  methods: {
    filtteroiLista() {
      this.filtteriLista = [];
      console.log("filtteröinti aloitetaan" + this.lajikeLista);
      if (this.vari === "iv") {
        this.filtteriLista = this.lajikeLista.filter(
          (el) => el.kukinnanVari === null
        );
      } else if (this.vari && this.valoisuus) {
        this.filtteriLista = this.lajikeLista.filter(
          (el) =>
            el.valonTarve === this.valoisuus && el.kukinnanVari === this.vari
        );
      } else if (this.vari && !this.valoisuus) {
        this.filtteriLista = this.lajikeLista.filter(
          (el) => el.kukinnanVari === this.vari
        );
      } else if (!this.vari && this.valoisuus) {
        this.filtteriLista = this.lajikeLista.filter(
          (el) => el.valonTarve === this.valoisuus
        );
      }
      console.log("filtteröity");
    },
    valitseKasvi(valittuObjekti) {
      console.log("Kasvi valittu: ", this.lajike);
      this.$emit("kasvinKlikkaus", valittuObjekti);
    },
  },
};
</script>
<style scoped></style>
