<template>
  <div class="buttoncss">
    <button
      :class="{
        buttonpassivecss: valikonNakyma != 'a',
        buttonactivecss: valikonNakyma === 'a',
      }"
      @click="penkinArvot('a')"
    >
      Muokkaa kukkapenkkiä
    </button>
    <button
      :class="{
        buttonpassivecss: valikonNakyma != 'b',
        buttonactivecss: valikonNakyma === 'b',
      }"
      @click="penkinArvot('b')"
    >
      Lisää kasveja
    </button>
    <button
      :class="{
        buttonpassivecss: valikonNakyma != 'c',
        buttonactivecss: valikonNakyma === 'c',
      }"
      @click="penkinArvot('c')"
    >
      Poista kasveja
    </button>
  </div>
</template>

<script>
export default {
  emits: ["valikostaValittu"],
  data() {
    return {
      istutaKasviValittu: false,
      valikonNakyma: "a",
    };
  },
  methods: {
    penkinArvot(klikattu) {
      console.log(
        "Voimassaoleva valikko: ",
        this.valikonNakyma,
        " Klikattiin: ",
        klikattu
      );
      this.valikonNakyma = klikattu;
      console.log("Muokataan valikoksi: ", this.valikonNakyma);
      this.$emit("valikostaValittu", klikattu);
      console.log(klikattu);
    },
  },
};
</script>

<style scoped>
/* .buttoncss {
  width: 56%;
  height: 5%;
  background: rgb(214, 58, 193);
  margin: auto;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-right: 2%;
  padding: 1px;
} */
.buttonactivecss {
  width: 150px;
  height: 50px;
  background: rgb(109, 218, 105);
  margin: auto;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 2%;
  padding: 1px;
}
.buttonpassivecss {
  width: 150px;
  height: 50px;
  background: rgb(150, 163, 149);
  margin: auto;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 2%;
  padding: 1px;
}
</style>
