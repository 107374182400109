<template>
  <section class="syottoalue">
    <div class="nimi">Anna pituus (pidempi mitta) metreinä:</div>
    <div class="arvo">
      <input type="text" v-model.number="xInput" @keyup="createPlantingArea" />
    </div>
    <div class="nimi">Anna alueen syvyys (pienempi mitta) metreinä:</div>
    <div class="arvo">
      <input type="text" v-model.number="yInput" @keyup="createPlantingArea" />
    </div>
    <div class="nimi">Alueen valoisuus:</div>
    <div class="arvo">
      <select v-model="valoisuus" name="" id="">
        <option value="aurinkoinen">Aurinkoinen</option>
        <option value="puolivarjo">Puolivarjo</option>
        <option value="varjo">Varjo</option>
      </select>
    </div>
    <div class="nimi">Maaperän tyyppi:</div>
    <div class="arvo">
      <select v-model="maapera" name="" id="">
        <option value="hapan">Hapan</option>
        <option value="kostea">Kostea</option>
        <option value="kuiva">Kuiva</option>
      </select>
    </div>
  </section>
  <div class="center, buttoncss">
    <button @click="bedValues()">Käännä kukkapenkki</button>
  </div>

  <table class="plantingareatable center" v-if="area.length > 0">
    <tr class="plantingareatr" v-for="x in area" :key="x">
      <td class="plantingareatd" v-for="y in x" :key="y">O</td>
    </tr>
  </table>
</template>

<script>
//import BaseButton from './UI/BaseButton.vue';
export default {
  //components: { BaseButton },
  emits: ["bedValues", "newArea"],

  data() {
    return {
      //buttonsDisabled: false,
      insertOk: false,
      notOk: false,
      xInput: 0.5,
      yInput: 0.5,
      valoisuus: null,
      maapera: null,
      area: [],
    };
  },
  methods: {
    createPlantingArea() {
      console.log("aloitus");
      this.area = [];
      const xGrid = (this.xInput * 100) / 10;
      const yGrid = (this.yInput * 100) / 10;
      for (let i = 0; i < yGrid; i++) {
        const tempArray = [];
        for (let j = 0; j < xGrid; j++) {
          tempArray.push({
            xSijainti: j,
            ySijainti: i,
            istutettuKasvi: null,
          });
        }
        this.area.push(tempArray);
      }
      this.$emit("newArea", this.area);
    },

    bedValues() {
      console.log("välitetään tiedot", this.valoisuus, this.maapera);
      this.$emit("bedValues", this.valoisuus, this.maapera);
    },
  },
};
</script>

<style scoped>
.syottoalue {
  width: 95%;
  height: 20%;
  background: rgb(182, 238, 166);
  margin: auto;
  padding: 2px;
}
.nimi {
  width: 60%;
  height: 5%;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 1%;
  background: rgb(182, 238, 166);
  float: left;
}
.arvo {
  width: 23%;
  height: 5%;
  margin-left: 2%;
  margin-right: 3%;
  margin-top: 2%;
  margin-bottom: 1%;
  float: left;
  background: rgb(182, 238, 166);
}
.buttoncss {
  width: 90%;
  height: 5%;
  background: rgb(182, 238, 166);
  margin: auto;
  margin-top: 3%;
  margin-bottom: 1%;
  padding: 1px;
}
.plantingareatable {
  border: 5px solid rgb(87, 23, 23);
  /*width: 75%;*/
}
.plantingareatr {
  background-color: rgb(19, 54, 150);
  border: 1px dotted chocolate;
  height: 30px;
  /* height: 35px; */
}
.plantingareatd {
  background-color: rgb(160, 59, 19);
  border: 1px dotted chocolate;
  width: 30px;
}
</style>
