<template>
  <section class="container">
    <section>
      <div class="vasenreauna">
        <selection-panel @valikostaValittu="whatTheFuck"> </selection-panel>
      </div>
    </section>
    <section>
      <div class="vasenreuna">
        <planting-area
          v-if="valikostaValittu === 'a'"
          @bedValues="valita"
          @newArea="newArea"
        ></planting-area>
        <flower-bed
          v-if="valikostaValittu != 'a'"
          :area="area"
          :valittuKasvi="valittuKasvi"
          :valikostaValittu="valikostaValittu"
          @bedValues="valita"
          @kuokiMaata="kuokiMaata"
        ></flower-bed>
      </div>
    </section>
    <section class="oikeareuna">
      <select-plant
        :maapera="maapera"
        :valoisuus="valoisuus"
        :lajikeLista="lajikeLista"
        @kasvinKlikkaus="mahdollistaIstutus"
      ></select-plant>
    </section>
  </section>
</template>

<script>
import PlantingArea from "./components/PlantingArea.vue";
import SelectPlant from "./components/SelectPlant.vue";
import FlowerBed from "./components/FlowerBed.vue";
import SelectionPanel from "./components/SelectionPanel.vue";
// this.$emit("valikostaValittu", klikattu);
export default {
  props: ["klikattu"],
  name: "App",
  components: {
    PlantingArea,
    SelectPlant,
    FlowerBed,
    SelectionPanel,
  },
  data() {
    return {
      valoisuus: null,
      maapera: null,
      lajikeLista: [],
      valittuKasvi: null,
      area: [],
      flowerbedSet: false,
      valikostaValittu: "a",
    };
  },
  methods: {
    valita(valoisuus, maapera) {
      this.valoisuus = valoisuus;
      this.maapera = maapera;
      console.log(valoisuus, maapera);
    },
    newArea(newAreaArray) {
      this.area = newAreaArray;
      this.flowerbedSet = true;
      console.log("Penkki käännetty");
    },
    getPlantsFromDB() {
      fetch("/api/plants")
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log("List not available");
          }
        })
        .then((data) => {
          this.lajikeLista = data.plants.reverse();
        })
        .catch((error) => {
          console.log("Virhe:" + error);
        });
    },
    kuokiMaata(y, x, valittuKasvi) {
      if (this.valikostaValittu === "b") {
        console.log("kuokiMaata AppVuessa" + y + x + valittuKasvi);
        this.area[y][x].istutettuKasvi = valittuKasvi;
      }
      if (this.valikostaValittu === "c") {
        console.log("kuokiMaata AppVuessa Poistaa kasvin");
        this.area[y][x].istutettuKasvi = null;
      }
    },
    mahdollistaIstutus(valittuObjekti) {
      this.valittuKasvi = valittuObjekti;
      console.log("voidaan istuttaa", valittuObjekti.lajike);
    },
    whatTheFuck(valikko) {
      console.log("Muokataan valikkoa whatTheFuckissa: " + valikko);
      // @valikostaValittu="updateValikko"
      this.valikostaValittu = valikko;
      console.log("Muutoksen?jälkeen  whatTheFuckissa: " + valikko);
    },
    /*    updateValikko(){
      this.valikonNakyma = @valikostaValittu
    } */
  },
  mounted() {
    this.getPlantsFromDB();
  },
};
</script>

<style>
.container {
  width: 95%;
  height: 700px;
  background: rgb(216, 125, 125);
  margin: auto;
  padding: 10px;
}

.vasenreuna {
  width: 73%;
  height: 98%;
  margin-left: 2%;
  margin-bottom: 2%;
  background: rgb(182, 238, 166);
  float: left;
}

.oikeareuna {
  width: 21%;
  height: 85%;
  margin-left: 2%;
  margin-right: 2%;
  float: left;
  background: rgb(182, 238, 166);
}
.ylavalikko {
  width: 100%;
  height: 50px;
  background-color: yellow;
}
.center {
  margin-left: auto;
  margin-right: auto;
}

</style>
