<template>
  <section>
    <div class="nimi">Alueen valoisuus:</div>
    <div class="arvo">
      <select @change="bedValues" v-model="valoisuus" name="" id="">
        <option value="aurinkoinen">Aurinkoinen</option>
        <option value="puolivarjo">Puolivarjo</option>
        <option value="varjo">Varjo</option>
      </select>
    </div>
    <div class="nimi">Maaperän tyyppi:</div>
    <div class="arvo">
      <select @change="bedValues" v-model="maapera" name="" id="">
        <option value="hapan">Hapan</option>
        <option value="kostea">Kostea</option>
        <option value="kuiva">Kuiva</option>
      </select>
    </div>
  </section>

  <table class="plantingareatable center" v-if="area.length > 0">
    <tr class="plantingareatr" v-for="x in area" :key="x">
      <td
        class="plantingareatd"
        v-for="y in x"
        :key="y"
        @click="kuokiMaata(y.ySijainti, y.xSijainti)"
      >
        <span v-if="y.istutettuKasvi === null" class="tyhjaPaikka"></span>
        <span class="valittuKasvi" v-if="y.istutettuKasvi != null"></span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ["valittuKasvi", "area", "valikostaValittu"],
  emits: ["bedValues", "kuokiMaata"],
  data() {
    return {
      insertOk: false,
      notOk: false,
      valoisuus: null,
      maapera: null,
    };
  },
  methods: {
    kuokiMaata(y, x) {
      console.log("istutettu: " + this.valittuKasvi);
      this.$emit("kuokiMaata", y, x, this.valittuKasvi);
    },
    bedValues() {
      console.log("välitetään tiedot", this.valoisuus, this.maapera);
      this.$emit("bedValues", this.valoisuus, this.maapera);
    },
  },
};
</script>

<style scoped>
.plantingareatable {
  border: 5px solid rgb(87, 23, 23);
  /*width: 75%;*/
}
.plantingareatr {
  background-color: rgb(19, 54, 150);
  border: 1px dotted chocolate;
  height: 30px;
  position: relative;
}
.plantingareatd {
  background-color: rgb(160, 59, 19);
  border: 1px dotted chocolate;
  width: 30px;
  position: relative;
}
.syottoalue {
  width: 95%;
  height: 20%;
  background: rgb(182, 238, 166);
  margin: auto;
  padding: 2px;
}
.nimi {
  width: 60%;
  height: 5%;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 1%;
  background: rgb(182, 238, 166);
  float: left;
}
.arvo {
  width: 23%;
  height: 5%;
  margin-left: 2%;
  margin-right: 3%;
  margin-top: 2%;
  margin-bottom: 1%;
  float: left;
  background: rgb(182, 238, 166);
}
.buttoncss {
  width: 90%;
  height: 5%;
  background: rgb(182, 238, 166);
  margin: auto;
  margin-top: 3%;
  margin-bottom: 1%;
  padding: 1px;
}
.valittuKasvi {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: rgb(66, 199, 111);
  border-radius: 50%;
  height: 46px;
  width: 46px;
  margin: 0;
}
.tyhjaPaikka {
  display: block;
  background: rgb(143, 56, 21);
  border-radius: 70%;
  height: 25px;
  width: 25px;
  margin: 0;
}
</style>
